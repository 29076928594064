<template>
  <div class="contain" v-title data-title="支付"></div>
</template>

<script>
import {
  qrCodeJump
} from '@/common/api'

export default {
  name: 'thPay',
  data () {
    return {
      type: '',
      link: '',
      resData: this.$route.query.m,
      amt: this.$route.query.amt,
      ordNo: this.$route.query.ordNo
    }
  },
  mounted () {
    // console.log(this.$route.query)
    // this.resData = '561B4D9FA5675B60E68A4AA135C74C51_MTI4MzE5MDU0NzcwMzQ4MDMy_MTI4MzE5MDY3MDA5MzI3MTA0'
    // this.amt = '0.01'
    // this.ordNo = 'DD22042456784646'
    if (/MicroMessenger/.test(window.navigator.userAgent)) {
      // this.type = 'WXMIN'
      // this.toWxLink()
    } else if (/AlipayClient/.test(window.navigator.userAgent)) {
      this.type = 'ALIMIN'
      this.toAliPay()
    } else {
      // this.type = 'WXMIN'
      // this.toWxLink()
    }
  },
  methods: {
    toWxLink () {
      this.$loading.show()
      const tmpData = {
        jumpType: this.type,
        m: this.resData,
        amt: this.amt,
        ordNo: this.ordNo
      }
      qrCodeJump(tmpData).then(res => {
        this.$loading.hide()
        this.link = res.resultObject
        location.href = this.link
      }).catch(err => {
        this.$loading.hide()
        console.error(err)
      })
    },
    toAliPay () {
      const tmpData = `jumpType=${this.type}&amt=${this.amt}&ordNo=${this.ordNo}&m=` + this.resData
      window.location.href = 'https://ds.alipay.com/?scheme=' + encodeURIComponent(`alipays://platformapi/startapp?appId=2021003105624350&page=pages/thPay/thPay&query=${encodeURIComponent(tmpData)}`)
    }
  }
}
</script>

<style scoped lang="scss">
.contain {
  padding: 40vh 10px 56px 10px;
  height: 100%;
}
</style>
